<template>
    <div class="main_con">
        <!-- <el-card>
            <h2>人员培训</h2>
            <el-table :data="train_record" height="250" border id="finish_train" >
                <el-table-column prop="train_time" label="日期" width="180"></el-table-column>
                <el-table-column prop="topic" label="培训主题"></el-table-column>
                <el-table-column prop="peoples" label="人数"></el-table-column>
                <el-table-column label="操作" width="50">跳转</el-table-column>
            </el-table>
        </el-card>  -->
        <el-card id="table" v-loading="loading">
            <h2>腾讯项目—金石审计检查计划</h2>
            <table>
                <thead>
                    <tr>
                        <th>日期</th>
                        <th>机房</th>
                        <th>审计安排</th>
                        <th>审计人</th>
                        <th>单号</th>
                        <th>实际完成日期</th>
                        <th>完成进度</th>
                        <th>区域负责人</th>
                        <th>相关记录</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in auditData" :key="index"
                        :style="{ backgroundColor: index % 2 !== 0 ? '#f7f7f7' : '' }">
                        <td>{{ item.Quarter }}季度</td>
                        <td>{{ item.idcpName }}</td>
                        <td>{{ item.AuditArrangement }}</td>
                        <td>{{ item.Auditor }}</td>
                        <td>{{ item.ticket_id }}</td>
                        <td>{{ item.real_complete_time }}</td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.RegionalManager }}</td>
                        <td>{{ item.log }}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</template>

<script>
import { get_train_record } from '@/api/train';
import * as echarts from 'echarts';
export default {
    name: "audit_plan",
    components: {},
    data() {
        let train_log = []
        let result;
        return {
            auditData: [
                { Quarter: "2024-Q4", idcpName: "广州华新园AC", Auditor: "王瑞江", ticket_id: "", real_complete_time: "", status: "", RegionalManager: "叶军", AuditArrangement: "华新园/云谷交叉审计" },
                { Quarter: "2024-Q4", idcpName: "广州电信云谷AC", Auditor: "刘合志", ticket_id: "", real_complete_time: "", status: "", RegionalManager: "叶军", AuditArrangement: "华新园/云谷交叉审计" },
                { Quarter: "2024-Q4", idcpName: "广州移动三水AC", Auditor: "郑永毅", ticket_id: "", real_complete_time: "", status: "", RegionalManager: "叶军", AuditArrangement: "三水机房内部审计" },
                { Quarter: "2024-Q4", idcpName: "清远腾讯清新DC", Auditor: "陶梓浩", ticket_id: "", real_complete_time: "", status: "", RegionalManager: "祁荣川", AuditArrangement: "清新机房内部审计" },
                { Quarter: "2024-Q4", idcpName: "太原移动太原泽信EC", Auditor: "王祥祥", ticket_id: "", real_complete_time: "", status: "", RegionalManager: "李仕彬", AuditArrangement: "太原EC内部审计" },
                { Quarter: "2024-Q4", idcpName: "武汉电信临空港EC", Auditor: "朱维", ticket_id: "", real_complete_time: "", status: "", RegionalManager: "李仕彬", AuditArrangement: "武汉EC内部审计" },
                { Quarter: "2024-Q4", idcpName: "西安移动西咸EC", Auditor: "陈杰", ticket_id: "", real_complete_time: "", status: "", RegionalManager: "李仕彬", AuditArrangement: "西安EC内部审计" },
                { Quarter: "2024-Q4", idcpName: "沈阳腾讯铁西EC", Auditor: "杨宇", ticket_id: "", real_complete_time: "", status: "", RegionalManager: "李仕彬", AuditArrangement: "沈阳EC内部审计" },
                { Quarter: "2024-Q4", idcpName: "郑州高新区移动EC", Auditor: "宋艺航", ticket_id: "", real_complete_time: "", status: "", RegionalManager: "李仕彬", AuditArrangement: "郑州EC内部审计" },
                { Quarter: "2024-Q4", idcpName: "长沙望城EC", Auditor: "唐友维", ticket_id: "", real_complete_time: "", status: "", RegionalManager: "李仕彬", AuditArrangement: "长沙EC内部审计" },
                { Quarter: "2024-Q4", idcpName: "南昌丰和南EC", Auditor: "温雪华", ticket_id: "", real_complete_time: "", status: "", RegionalManager: "李仕彬", AuditArrangement: "南昌EC内部审计" }
            ]
        };
    },
    mounted() {
        this.get_train_record()
    },
    created() {
    },
    methods: {
        material_str(str) {
            if (str == '') {
                return
            }
            let name_1 = str.split("/")[7]
            let name = name_1.split("_")
            name = name.splice(0, name.length - 1)
            return name.join("_")
        },
        openDialog(topic, month) {
            let current_topic = this.params_table.findIndex(item => item.topic == topic)
            const buttons = this.$refs.monthButtons;
            this.current_topic = this.params_table[current_topic].record[month]
            this.dialog = true
        },
        changeYear(year) {

        },
        get_train_record(year = null) {
            if (year == null) {
                this.current_year = new Date().getFullYear();
            } else {
                this.current_year = year
            }
            let result = []
            get_train_record().then(response => {
                let temp = response.data.data
                temp.sort((a, b) => new Date(a.train_time) - new Date(b.train_time));
                temp.forEach(element => {
                    let year = element.train_time.split("-")[0]
                    if (this.years.findIndex((ele, index) => ele == year)) {
                        this.years.push(year)
                    }
                    if (year != this.current_year) countinue;
                    let index = result.findIndex(item => item.topic == element.topic)
                    let month = element.train_time.split("-")[1]
                    element.sign = element.sign.split(";")
                    element.material = element.material.split(";")
                    element.img = element.img.split(";")
                    if (index == -1) {
                        result.push({
                            "topic": element.topic,
                            "type": element.type,
                            "month": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            "record": Array.from({ length: 12 }, () => []), // 创建12个空数组
                        })
                        result[result.length - 1].month[month - 1] += 1
                        result[result.length - 1].record[month - 1].push(element);
                    } else {
                        result[index].month[month - 1] += 1
                        result[index].record[month - 1].push(element);
                    }
                })
                this.params_table = result
                this.loading = false
            }).catch(() => {
                // this.loading=false
            })
        },
        getDateRangeFromWeek(weekString) {
            // 解析输入的字符串
            const [yearMonth, week] = weekString.split('-week');
            const [year, month] = yearMonth.split('-').map(Number)
            const lastDayOfCurrentMonth = new Date(year, month, 0).getDate();
            console.log(lastDayOfCurrentMonth)
            // 创建存放7天的数组
            const dateRange = [];
            for (let i = ((week - 1) * 7) + 1; i <= week * 7 && i <= lastDayOfCurrentMonth; i++) {
                dateRange.push(yearMonth + `-${i < 10 ? `0${i}` : i}`); // 格式化为YYYY-MM-DD
            }
            //返回这周的所有时间
            return dateRange;
        },
        getStatusClass({ row, column, rowIndex, columnIndex }) {
            // 根据状态返回不同的类名
            if (columnIndex == 2) {
                return row.status === '已完成' ? 'success_content' : 'warning_content';
            }
        }
    },
};
</script>
<style scoped>
.main_con {
    text-align: center;
    padding: 16px;
}

.progressChart {
    width: 95%;
    height: 420px;
    border: 1px solid #aaa;
    margin: 0 auto;
    margin-top: 30px;
}

.success_content {
    background-color: #daffa2;
}

.graph {
    margin-top: 16px;
}

.warning_content {
    background-color: rgb(255 160 160 / 60%);
}

.right {
    display: flex;

    p {
        font-size: 14px;
        line-height: 14px;
        color: #333;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .line {
            display: inline-block;
            width: 100%;
            height: 3px;
            background: #1890ff;
            border-radius: 2px;
            margin-top: 8px;
        }
    }

    .line {
        display: inline-block;
        width: 100%;
        height: 3px;
        background: #1890ff;
        border-radius: 2px;
        margin-top: 8px;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
}

td.left-align {
    text-align: left;
}
</style>